// AddSlider.js
import { useState } from "react";

const AddSlider = () => {
  const [pcImage, setPcImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [order, setOrder] = useState(1);
  const [status, setStatus] = useState("draft");
  const [link, setLink] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name,
      link,
      status,
      order,
      pcImageUrl: pcImage || "",
      mobileImageUrl: mobileImage || "",
    };

    fetch(
      "https://flormar-web-server-nayem55-flormar-bd.vercel.app/add-slider",
      {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((res) => res.json())
      .then((data) => alert("Slider added!"))
      .catch((err) => console.error(err));
  };

  return (
    <div className="max-w-lg mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Slider</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="name"
          onChange={(e) => setName(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          placeholder="Slider Name"
        />
        <input
          type="text"
          onChange={(e) => setPcImage(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          placeholder="Desktop image link"
          required
        />
        <input
          type="text"
          onChange={(e) => setMobileImage(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          placeholder="Mobile image link"
          required
        />
        <input
          type="number"
          placeholder="Order"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          required
        />
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          required
        >
          <option value="draft">Draft</option>
          <option value="published">Published</option>
        </select>
        <input
          type="text"
          placeholder="Redirect link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          required
        />
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded"
        >
          Add Slider
        </button>
      </form>
    </div>
  );
};

export default AddSlider;
